import React, { useState, useEffect } from "react";
import {
  Button,
  Tabs,
  Form,
  Input,
  Col,
  Row,
  ColorPicker,
  message,
  Select,
  Image
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import { addWidget, getWidgetdata } from "../../redux/Widget/WidgetActions";
import * as actionTypes from "../../redux/Widget/WidgetTypes.js";

import image1 from "../../assets/image/img-1.png";
import image2 from "../../assets/image/img-2.png";
import image3 from "../../assets/image/img-3.png";
import image4 from "../../assets/image/img-4.png";
import convochat from '../../assets/image/chatcircularbutton.png'
import defaultIcon from "../../assets/image/defaultIcon.svg";
import Tab1 from "./tab1";
import Tab3 from "./tab3";
import Tab4 from "./tab4"; // Import the Tab4 component here
import Tab5 from "./tab5";
import Tab6 from "./tab6";
import Tab7 from "./tab7";
import BrandDiv from "./brandDiv";
import { Get_All_Brands, Get_Brands_By_Id } from "../../redux/Brands/BrandsActions";
import { Abc, FunctionsRounded } from "@mui/icons-material";
import ButtonTab from "./buttonTab";

const Widget = () => {
  const userData = useSelector((state) => state.Auth.userData);
  const allDataBrand = useSelector((state) => state.Brands.data);
  const [BrandToken, setBrandToken] = useState(null);
  const usertoken = useSelector((state) => state.Auth.token);
  let filtredBrands = allDataBrand?.map((i) => {
    return {
      label: i?.brandname,
      value: i?.token
    }
  })
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      companyId: userData?.companyId
    };
    if (userData?.role === "superadmin") {
      dispatch(Get_All_Brands(usertoken));
    } else {
      dispatch(Get_Brands_By_Id(data, usertoken));
    }
  }, []);

  useEffect(() => {
    const data = {
      brand_token: BrandToken
    };
    dispatch(getWidgetdata(data, usertoken));

    return () => {
      dispatch({
        type: actionTypes.WIDGET_GET_CLEAN
      });
    }
  }, [BrandToken]);

  const onChange = (key) => { };
  const handleChangeDrop = (value) => {
    setBrandToken(value)
  };
  const items = [
    {
      key: "1",
      label: "Get Started",
      children: (
        <TabPaneWrapper>
          <Tab3 />
        </TabPaneWrapper>
      ),
    },

    {
      key: "3",
      label: "Appearance",
      children: (
        <TabPaneWrapper>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#56777a" }}>
            <h6 style={{ paddingLeft: 10, color: "#fff" }}>Website</h6>
            <Select
              placeholder="Select Webiste"
              value={BrandToken}
              // defaultValue="Chat"
              style={{ width: 150, marginBottom: 15, marginRight: 20 }}
              onChange={handleChangeDrop}
              options={filtredBrands}
            />
          </div>
          <Tab2 BrandToken={BrandToken} />

        </TabPaneWrapper>
      ),
    },
  ];
  return (
    <section>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </section>
  );
};

export function TabPaneWrapper({ children, ...props }) {
  return <div {...props}>{children}</div>;
}

const Tab2 = ({ BrandToken }) => {

  const dispatch = useDispatch();
  const [bgcolor, setBgColor] = useState("");
  const [visitorBg, setvisitorBg] = useState("");
  const [headerLable, setheaderLable] = useState("");
  const [banner1, setbanner1] = useState("");
  const [banner2, setbanner2] = useState("");
  const [chatLabel, setchatLabel] = useState("Chat");
  const [heightchat, setheightchat] = useState(4);
  const [widthchat, setwidthchat] = useState(15);
  const [borderChat, setborderChat] = useState(50);
  const [Error, setError] = useState({
    label: "",
  });
  // const [bgcolor, setBgColor] = useState("#2e3192");
  const [txtcolor, setTxtColor] = useState("#fff");
  const [isLabled, setisLabled] = useState(true);
  const [isIcons, setisIcons] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image
  // chat Badge
  const [titleLable, settitleLable] = useState("Let me help");
  const [bgcolorBadge, setbgcolorBadge] = useState("#2e3192");
  const [currentView, setcurrentView] = useState("Chat");
  const [lastActiveTAB, setlastActiveTAB] = useState("");
  const [SelectedImagebase64, setSelectedImagebase64] = useState(null);
  const [oldPicture, setOldPicture] = useState(null);
  const [activekeyid, setactivekeyid] = useState(null);

  const [NewData, setNewData] = useState(null);
  const userData = useSelector((state) => state.Auth.userData);
  const usertoken = useSelector((state) => state.Auth.token);
  const data = useSelector((state) => state.WidgetReducer.widgetData);
  const widgetLoading = useSelector((state) => state.WidgetReducer.widgetLoading);
  const isLoading = useSelector((state) => state.WidgetReducer.isLoadingwidget);

  const handleChangeDrop = (value) => {
    if (value == "Chat") {
      setlastActiveTAB(selectedTab);
      setSelectedTab("");
      setcurrentView(value);
    } else {
      setcurrentView(value);
      lastActiveTAB !== "" ? setSelectedTab(lastActiveTAB) : setSelectedTab("Tab4");
    }
  };

  //
  const handleChangeColor = (selectedColor, hex) => {
    setBgColor(hex);
  };
  const handleChangeColorVisitor = (selectedColor, hex) => {
    setvisitorBg(hex);
  };

  const onSubmit = async (a) => {
    // Read the image file as a base64 encoded string
    let data = {
      image: SelectedImagebase64,
      user_id: userData?._id,
      brand_token: BrandToken,
      pop_up: {
        pop_up_bgcolor: bgcolor,
        pop_up_visitorBg: visitorBg,
        pop_up_bannerFirst: banner1,
        pop_up_bannerSecend: banner2,
        pop_up_headerLable: headerLable,
      },
      badge: {
        background_color: bgcolorBadge,
        title: titleLable,
        text_color: txtcolor,
      },
      active: activekeyid
    };
    dispatch(addWidget(data, usertoken, setSelectedImagebase64, message));
    // setSelectedImagebase64(null);
  };


  const onChangebadge = (key) => {
    setcurrentView("Badge");
    setactivekeyid(key);
  };
  const [selectedTab, setSelectedTab] = useState(""); // Initialize with a default tab
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };



  // Functions
  const handleImageChange = (info) => {
    if (info.file.originFileObj !== undefined) {
      setSelectedImage(info.file.originFileObj);
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImagebase64(reader.result);
      };
      reader.readAsDataURL(info.file.originFileObj);
    }
  };

  const handleChangeColors = (selectedColor, hex) => {
    setbgcolorBadge(hex);
  };
  const handleChangeTxtColor = (selectedColor, hex) => {
    setTxtColor(hex);
  };
  // End

  const badgeitems = [
    {
      key: "1",
      label: (
        <div className="chatbadgeImg" onClick={() => handleTabClick("Tab4")}>
          <Image width={60} src={image1} preview={false} /> <p>Image Right</p>
        </div>
      ),
      children: (
        <TabPaneWrapper>
          <Tab4
            oldPicture={oldPicture}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            titleLable={titleLable}
            settitleLable={settitleLable}
            bgcolorBadge={bgcolorBadge}
            setbgcolorBadge={setbgcolorBadge}
            handleImageChange={handleImageChange}
            handleChangeColors={handleChangeColors}
            txtcolor={txtcolor}
            setTxtColor={setTxtColor}
            handleChangeTxtColor={handleChangeTxtColor}
            defaultIcon={defaultIcon}
          />
        </TabPaneWrapper>
      ),
    },
    {
      key: "2",
      label: (
        <div className="chatbadgeImg" onClick={() => handleTabClick("Tab5")}>
          <Image width={60} src={image2} preview={false} /> <p>Image Left</p>
        </div>
      ),
      children: (
        <TabPaneWrapper>
          <Tab5
            oldPicture={oldPicture}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            titleLable={titleLable}
            settitleLable={settitleLable}
            bgcolorBadge={bgcolorBadge}
            setbgcolorBadge={setbgcolorBadge}
            handleImageChange={handleImageChange}
            handleChangeColors={handleChangeColors}
            txtcolor={txtcolor}
            setTxtColor={setTxtColor}
            handleChangeTxtColor={handleChangeTxtColor}
            defaultIcon={defaultIcon}
          />
        </TabPaneWrapper>
      ),
    },
    {
      key: "3",
      label: (
        <div className="chatbadgeImg" onClick={() => handleTabClick("Tab6")}>
          <Image width={60} src={image3} preview={false} /> <p>Text Only</p>
        </div>
      ),
      children: (
        <TabPaneWrapper>
          <Tab6
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            titleLable={titleLable}
            settitleLable={settitleLable}
            bgcolorBadge={bgcolorBadge}
            setbgcolorBadge={setbgcolorBadge}
            handleImageChange={handleImageChange}
            handleChangeColors={handleChangeColors}
            txtcolor={txtcolor}
            setTxtColor={setTxtColor}
            handleChangeTxtColor={handleChangeTxtColor}
          />
        </TabPaneWrapper>
      ),
    },
    {
      key: "4",
      label: (
        <div className="chatbadgeImg" onClick={() => handleTabClick("Tab7")}>
          <Image width={60} src={image4} preview={false} /> <p>Custom Image</p>
        </div>
      ),
      children: (
        <TabPaneWrapper>
          <Tab7
            oldPicture={oldPicture}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            titleLable={titleLable}
            settitleLable={settitleLable}
            bgcolorBadge={bgcolorBadge}
            setbgcolorBadge={setbgcolorBadge}
            handleImageChange={handleImageChange}
            handleChangeColors={handleChangeColors}
          />
        </TabPaneWrapper>
      ),
    },
    {
      key: "5",
      label: (
        <div className="chatbadgeImg" onClick={() => handleTabClick("Tab1")}>
          <Image width={65} height={55} src={convochat} preview={false} /> <p>Chat button</p>
        </div>
      ),
      children: (
        <TabPaneWrapper>
          <ButtonTab
            bgcolorBadge={bgcolorBadge}
            setbgcolorBadge={setbgcolorBadge}
            handleImageChange={handleImageChange}
            handleChangeColors={handleChangeColors} />
        </TabPaneWrapper>
      ),
    },
  ];

  useEffect(() => {
    if (data !== null) {
      setNewData(data);
      setbanner1(data?.pop_up?.pop_up_bannerFirst);
      setbanner2(data?.pop_up?.pop_up_bannerSecend);
      setheaderLable(data?.pop_up?.pop_up_headerLable);
      setvisitorBg(data?.pop_up?.pop_up_visitorBg);
      setBgColor(data?.pop_up?.pop_up_bgcolor);
      settitleLable(data?.badge?.title);
      setbgcolorBadge(data?.badge?.background_color);
      setTxtColor(data?.badge?.text_color);
      setactivekeyid(data?.active);
      setOldPicture(data?.badge?.image);
    }
    else {
      setNewData(null);
    }
  }, [data]);


  return (
    <Row className="addShortcut">
      {widgetLoading ? <div>
        Loading...
      </div>
        :
        <>
          {NewData !== null ? <>  <Col span={12} className="badgeDiv">
            <h6>Chat Window</h6>
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 400,
              }}
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
            >
              <Form.Item label="Header Label" name="headerlabel">
                <Input
                  defaultValue={headerLable}
                  onChange={(e) => setheaderLable(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Banner Label 1" name="bannerlabel1">
                <Input
                  defaultValue={banner1}
                  onChange={(e) => setbanner1(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Banner Label 2" name="bannerlabel2">
                <Input
                  defaultValue={banner2}
                  onChange={(e) => setbanner2(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Background Color" name="bgcolor">
                <ColorPicker
                  format={"hex"}
                  defaultFormat={"hex"}
                  defaultValue={bgcolor}
                  color={bgcolor}
                  onChange={(value, hex) => handleChangeColor(value, hex)}
                  size="large"
                  showText
                />
              </Form.Item>
              <Form.Item label="Background Visitor" name="bgcolorv">
                <ColorPicker
                  format={"hex"}
                  defaultFormat={"hex"}
                  defaultValue={visitorBg}
                  color={visitorBg}
                  onChange={(value, hex) => handleChangeColorVisitor(value, hex)}
                  size="large"
                  showText
                />
              </Form.Item>
            </Form>
            <div>
              <div className="perviewHead">
                <h6>Chat Badge</h6>
              </div>
              <Tabs
                defaultActiveKey={activekeyid}
                items={badgeitems}
                onChange={onChangebadge}
              />
              <Form.Item>
                <Button
                  disabled={isLoading}
                  style={{ marginTop: 10 }}
                  type="primary"
                  onClick={() => onSubmit()}
                >
                  Save Changes
                </Button>
              </Form.Item>
            </div>
          </Col>
            <Col span={12}>
              <div className="perviewHead">
                <h6 style={{ paddingLeft: 10 }}>Preview</h6>
                <Select
                  value={currentView}
                  defaultValue="Chat"
                  style={{ width: 130, marginBottom: 15 }}
                  onChange={handleChangeDrop}
                  options={[
                    { value: 'Chat', label: 'Chat Window' },
                    { value: 'Badge', label: 'Badge' },
                  ]}
                />
              </div>
              {/* df */}
              <BrandDiv
                activekeyid={activekeyid}
                oldPicture={oldPicture}
                currentView={currentView}
                selectedTab={selectedTab}
                bgcolor={bgcolor}
                headerLable={headerLable}
                banner1={banner1}
                banner2={banner2}
                visitorBg={visitorBg}
                chatLabel={chatLabel}
                heightchat={heightchat}
                widthchat={widthchat}
                borderChat={borderChat}
                txtcolor={txtcolor}
                isLabled={isLabled}
                isIcons={isIcons}
                setchatLabel={setchatLabel}
                setError={setError}
                setBgColor={setBgColor}
                setTxtColor={setTxtColor}
                setheightchat={setheightchat}
                setwidthchat={setwidthchat}
                setborderChat={setborderChat}
                setisLabled={setisLabled}
                setisIcons={setisIcons}
                selectedImage={selectedImage}
                titleLable={titleLable}
                bgcolorBadge={bgcolorBadge}
                setbgcolorBadge={setbgcolorBadge}
                handleImageChange={handleImageChange}
                handleChangeColors={handleChangeColors}
                handleChangeTxtColor={handleChangeTxtColor}
                defaultIcon={defaultIcon}
              />
            </Col>
          </>
            :
            <div>
              No Data Found, (Please Select Website.)
            </div>
          }

        </>}

    </Row>
  );
};

export default Widget;
