
import React from 'react';
import { Line } from 'react-chartjs-2';

const LineChart = ({ AnalyticsData }) => {
    // Sample data for the line chart
    let allKeys = AnalyticsData?.TotalChats.length > 0 ? Object.keys(AnalyticsData?.TotalChats[0]) :  ["There is no Data Currently"];
    let allValues = AnalyticsData?.TotalChats.length > 0 ? Object.values(AnalyticsData?.TotalChats[0]) : [0];
    const data = {
        labels: allKeys,
        datasets: [
            {
                label: 'Total Chat',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderWidth: 2,
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: allValues,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return <div style={{ display: "flex", justifyContent: "center", alignItems: "end" ,height:410}}><Line data={data} options={options} /></div>;
};

export default LineChart;
