import { Breadcrumb, Layout, theme } from "antd";
import React, { useState, useEffect } from "react";
import Headers from "../../components/headers/headers";
import Sidebarr from "../../components/sidebar/sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row } from "antd";
import {
  FundOutlined,
  SearchOutlined,
  SettingOutlined,
  ThunderboltOutlined,
  UserSwitchOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
const { Content, Footer } = Layout;
const HomePage = () => {
  const userData = useSelector((state) => state.Auth.userData);
  // const usertoken = useSelector((state) => state.Auth.token);

  // const {
  //   token: { colorBgContainer }
  // } = theme.useToken();

  const items = [
    {
      key: "0",
      title: (
        <>
          <SettingOutlined /> Widget
        </>
      ),
      content: <div>Embed and customize the widget on your website.</div>,
      href: "widget"
    },
    {
      key: "1",
      title: (
        <>
          <UserSwitchOutlined /> Visitors
        </>
      ),
      content: <div>See a list of visitors to your website and start a conversation.</div>,
      href: "visitors"
    },
    {
      key: "2",
      title: (
        <>
          <FundOutlined /> History
        </>
      ),
      content: <div>Track the conversations you have with customers.</div>,
      href: "history"
    },
    {
      key: "3",
      title: (
        <>
          <ThunderboltOutlined /> Tickets
        </>
      ),
      content: <div>See customers request ticket while you offline.</div>,
      href: "tickets"
    }
  ];
  return (
    <>
      <div className="home">
        <h6>Hello, {userData?.sudoName}!</h6>
        <Row gutter={16}>
          {items.map((index, key) => (
            <Col key={key} xs={24} sm={12} md={6} lg={6} xl={6}>
              <Card
                // key={index}
                className="homeWidget"
                title={index.title}
                bordered={true}>
                {index.content}
                <Link to={index.href}> {index.href}</Link>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default HomePage;
