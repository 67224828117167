import React, { useState, useEffect, useContext } from "react";
import { Layout } from "antd";
import Footers from "../footers/footers";
import Sidebarr from "../sidebar/sidebar";
import Headers from "../headers/headers";
import RightDrawer from "../rightDrawer/RightDrawer";
import { SocketContext } from "../../context/socketContext";
import Marquee from 'react-fast-marquee';
const { Content } = Layout;
const Layouts = ({ children, collapsed, setCollapsed }) => {


  const { connected } = useContext(SocketContext);


  return (
    <Layout className="layout">
      <Sidebarr collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout style={{ paddingLeft: collapsed ? 80 : 200, transition: 'margin-left 0.5s', position: "relative" }} className="site-layout">

        <div style={{ zIndex: 1 }}>
          <Headers />
        </div>
        <Content className="content">
          {/* {!connected && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backdropFilter: 'blur(5px)',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                zIndex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{ width: '100%' }}> <p style={{ color: "red", fontSize: 18, }} > <Marquee>
                  Connecting to Server... Please Wait...
                </Marquee></p></div>
            </div>
          )} */}
          {children}</Content>
        {/* <RightDrawer /> */}
        <Footers />
      </Layout>
    </Layout>

  );
};

export default Layouts;
