import { PlusOutlined } from "@ant-design/icons";
import { ColorPicker, Form, Input, message, Upload } from "antd";
import React from "react";

const Tab5 = ({
  titleLable,
  settitleLable,
  bgcolorBadge,
  handleImageChange,
  handleChangeColors,
  handleChangeTxtColor,
  txtcolor,
  selectedImage,
  oldPicture
}) => {
  const checkImageSizeAndDimensions = (file) => {
    const maxSize = 1 * 1024 * 1024; // 1MB in bytes
    const maxWidth = 200;
    const maxHeight = 200;
    // var abc =
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (file.size > maxSize) {
          message.error("File size exceeds 1MB limit");
          reject("File size exceeds 1MB limit");
        } else if (img.width > maxWidth || img.height > maxHeight) {
          message.error("Image dimensions must be 100x100 pixels or less");
          reject("Image dimensions must be 100x100 pixels or less");
        } else {
          resolve(file);
        }
      };

      img.onerror = () => {
        reject("Invalid image format");
      };
    });
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 400 }}
        autoComplete="off"
      >
        <Form.Item label="Background Color" name="">
          <ColorPicker
            format={"hex"}
            defaultFormat={"hex"}
            defaultValue={bgcolorBadge}
            color={bgcolorBadge}
            onChange={(value, hex) => handleChangeColors(value, hex)}
            size="large"
            showText
          />
        </Form.Item>
        <Form.Item label="Title" name="name">
          <Input
            defaultValue={titleLable}
            onChange={(e) => settitleLable(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Title Color" name="txtcolor">
          <ColorPicker
            format={"hex"}
            defaultFormat={"hex"}
            defaultValue={txtcolor}
            color={txtcolor}
            onChange={(value, hex) => handleChangeTxtColor(value, hex)}
            size="large"
            showText
          />
        </Form.Item>
        <Form.Item label="Image" name="">
          <Upload
            listType="picture-card"
            onChange={handleImageChange}
            beforeUpload={checkImageSizeAndDimensions} // Check file size and dimensions before uploading
            maxCount={1}
            showUploadList={{
              showPreviewIcon: false,
              showRemoveIcon: false,
            }}
          >
            {selectedImage ? (
              uploadButton
            ) : oldPicture !== null ? (< img src={oldPicture} alt="preview" style={{ width: '100%' }} />)
              : (
                uploadButton
              )}
          </Upload>
          <p>
            Maximum size 1 MB. Maximum dimensions 100x100px. To support retina
            displays, recommended dimensions are 200x200px.
          </p>
        </Form.Item>
      </Form>
    </>
  );
};

export default Tab5;
