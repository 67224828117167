import React, { useEffect, useState } from 'react';
import BarChart from '../../components/charts/BarChart';
import { Doughnuts } from '../../components/charts/Doughnuts';
import { Col, Row, Select } from 'antd';
import { PieChart } from '../../components/charts/PieChart';
import LineChart from '../../components/charts/LineChart';
import { Card, Space } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { Get_Analtyics } from '../../redux/Analytics/AnalyticsActions';

const Analytics = () => {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.Auth.userData);
    const usertoken = useSelector((state) => state.Auth.token);
    const AnalyticsData = useSelector((state) => state.AnalyticsReducer.AnalyticsData);
 


    const [SelectedValue, setSelectedValue] = useState(null);

    let BrandName = userData?.assignBrands?.map((i) => {
        return {
            value: i?.token,
            label: i?.brandname
        }
    });
    useEffect(() => {
        let data = {
            token: SelectedValue == null ? userData?.assignBrands[0]?.token : SelectedValue
        }
        dispatch(Get_Analtyics(data, usertoken));
    }, []);

    const handleChange = (value) => {
        setSelectedValue(value);
        let data = {
            token: value
        }
        dispatch(Get_Analtyics(data, usertoken));
    };
    return (
        <>
            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", alignItems: "flex-end", }}>
                <Select
                    defaultValue={userData?.assignBrands[0]?.token}
                    style={{
                        width: 170,
                        marginRight: 20, marginTop: 5
                    }}
                    onChange={handleChange}
                    options={BrandName}
                />
            </div>
            {AnalyticsData !== null && <>
                <div>
                    <Row>
                        <Col span={13}>
                            <Card
                                title={"Total Visits"}
                                headStyle={{
                                    textAlign: 'center',
                                }}
                                style={{
                                    width: "99%",
                                    margin: "5px 0px"
                                }}
                            >

                                <BarChart AnalyticsData={AnalyticsData} />
                            </Card>
                        </Col>
                        <Col span={11}>
                            <Card
                                title={"Total WebPages Visits"}
                                headStyle={{
                                    textAlign: 'center',
                                }}

                                style={{
                                    width: "99%",
                                    margin: "5px 0px"
                                }}
                            >
                                <Doughnuts AnalyticsData={AnalyticsData} />
                            </Card>

                        </Col>
                    </Row>
                    <Row>
                        <Col span={13}>
                            <Card
                                title={"Total Chats"}
                                headStyle={{
                                    textAlign: 'center',
                                }}
                                style={{
                                    width: "99%",
                                    margin: "5px 0px"
                                }}
                            >
                                <LineChart AnalyticsData={AnalyticsData} />
                            </Card>
                        </Col>
                        <Col span={11}>
                            <Card
                                title={"Ticket Status"}
                                headStyle={{
                                    textAlign: 'center',
                                }}
                                style={{
                                    width: "99%",
                                    margin: "5px 0px"
                                }}
                            >
                                <PieChart AnalyticsData={AnalyticsData} />

                            </Card>
                        </Col>
                    </Row>
                </div>

            </>}
        </>
    );
}

export default Analytics;
