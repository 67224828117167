import { PlusOutlined } from "@ant-design/icons";
import { ColorPicker, Form, Input, message, Upload } from "antd";
import React from "react";

const Tab6 = ({
  titleLable,
  settitleLable,
  bgcolorBadge,
  handleChangeColors,
  handleChangeTxtColor,
  txtcolor
}) => {
  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 400 }}
        autoComplete="off"
      >
        <Form.Item label="Background Color" name="">
          <ColorPicker
            format={"hex"}
            defaultFormat={"hex"}
            defaultValue={bgcolorBadge}
            color={bgcolorBadge}
            onChange={(value, hex) => handleChangeColors(value, hex)}
            size="large"
            showText
          />
        </Form.Item>
        <Form.Item label="Title" name="name">
          <Input
            defaultValue={titleLable}
            onChange={(e) => settitleLable(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Title Color" name="txtcolor">
          <ColorPicker
            format={"hex"}
            defaultFormat={"hex"}
            defaultValue={txtcolor}
            color={txtcolor}
            onChange={(value, hex) => handleChangeTxtColor(value, hex)}
            size="large"
            showText
          />
        </Form.Item>
   
      </Form>
    </>
  );
};

export default Tab6;
