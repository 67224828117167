import React, { useEffect } from "react";
import { Form, Input, Button, Checkbox, Col, Row, Spin, message } from "antd";
import { UserOutlined, LoadingOutlined, ToolOutlined, GlobalOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import loginBack from "../../../assets/image/login-back.png";
import getStart from "../../../assets/image/getStart.png";
import logo from "../../../assets/image/main-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { get_Packegs, get_Plan_Types, SignUp_Action } from "../../../redux/Auth/AuthActions";

const SignupPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [rememberMe, setrememberMe] = React.useState(true);

    useEffect(() => {
        dispatch(get_Plan_Types());
        // dispatch(get_Packegs());
    }, []);

    const onFinish = (values) => {
        const data = {
            name: values.fullName,
            email: values.email,
            password: values.password,
            sudoName: values.fullName,
            role: 'admin',
            companyName: values.companyName,
            status: false,
            active: true
        };
        // let newData = { ...data, packageId: CurrentData?._id };
        // dispatch(SignUp_Action(newData, rememberMe));
        // navigate("/pricing-plan", { state: { data: data, rememberMe: rememberMe } });
    };

    return (
        <>
            <Row
                type="flex"
                justify="center"
                align="middle"
                className="loginForm"
                style={{ minHeight: "100vh", backgroundImage: `url(${loginBack})` }}
            >
                <Col span={4} className="sideBlue">
                    <img src={getStart} />
                </Col>
                <Col span={8} className="formSide">
                    <img src={logo} />
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        autoComplete="on"
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Row gutter={[24, 8]}>
                            <Col span={12}>
                                <Form.Item
                                    name="fullName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your full name!",
                                        },
                                    ]}
                                    label={
                                        <span>
                                            <UserOutlined /> Full Name
                                        </span>
                                    }
                                    className="loginLabel"
                                >
                                    <Input placeholder="Full Name" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'Please enter valid email!',
                                        },
                                        {
                                            required: true,
                                            message: "Please enter your email!",
                                        },
                                    ]}
                                    label={
                                        <span>
                                            <UserOutlined /> Email Address
                                        </span>
                                    }
                                    className="loginLabel"
                                >
                                    <Input placeholder="Email" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="password"
                                    label={
                                        <span>
                                            <ToolOutlined /> Password
                                        </span>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your password!",
                                        },
                                    ]}
                                    className="loginLabel"
                                >
                                    <Input.Password
                                        style={{
                                            outline: '0',
                                            border: 'none',
                                            width: '100%',

                                        }}
                                        placeholder="Password"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item
                                    name="confirmPassword"
                                    dependencies={['password']}
                                    label={
                                        <span>
                                            <ToolOutlined /> Confim Password
                                        </span>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please confirm your password!",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Confirm password does not match!'));
                                            },
                                        }),
                                    ]}
                                    className="loginLabel"
                                >
                                    <Input.Password
                                        style={{
                                            outline: '0',
                                            border: 'none',
                                            width: '100%',

                                        }}
                                        placeholder="Confirm Password"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24} >
                                <Form.Item
                                    name="companyName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your company name",
                                        },
                                    ]}
                                    label={
                                        <span>
                                            <GlobalOutlined /> Company Name
                                        </span>
                                    }
                                    className="loginLabel"
                                >
                                    <Input placeholder="Company Name" />
                                </Form.Item>
                            </Col>
                            {/* <Col span={12} ></Col> */}
                        </Row>
                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox checked={rememberMe} onChange={(e) => setrememberMe(e.target.checked)} >Remember me</Checkbox>
                            </Form.Item>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                            >
                                {"Sign Up"}
                            </Button>
                        </Form.Item>
                    </Form>
                    <p style={{ fontSize: 12, textAlign: "center" }}>Already have an account? <Link to={"/login"}>Login</Link></p>
                    <p className="terms">
                        By clicking Sign Up. I confirm that I have read and agree to the
                        Userthemes <span> Terms of Services & Privacy Policy </span> and to
                        receive email and updates.
                    </p>
                </Col>
            </Row>
        </>
    );
};

export default SignupPage;
