import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useRef } from "react";
import { fileChanger } from "../../helpers/fileChanger";

export const Downloader = ({ file, filename, }) => {
  const link = useRef(null);
  const url = URL.createObjectURL(fileChanger(file?.body, file?.filetype));
  //   useEffect(
  //     () => {
  //       link.current.click();

  //       return () => {
  //         URL.revokeObjectURL(url);
  //       };
  //     },
  //     []
  //   );

  const handleDownload = () => {
    link.current.click()
  }

  return (
    <>
      <Button onClick={handleDownload}><DownloadOutlined /></Button>
      <a style={{ display: "none" }} ref={link} href={url} download={filename}>
        Table export
      </a>
    </>
  );
};
