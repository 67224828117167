import React, { useState, useEffect } from "react";
import { Divider, Radio, Table, theme, Button, Space, Dropdown } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import { useDispatch, useSelector } from "react-redux";
import { getTickets, updateTickets } from "../../redux/Tickets/TicketsActions";

const Tickets = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ticketsData = useSelector((state) => state.TicketsReducer.data);
    const userData = useSelector((state) => state.Auth.userData);
    const usertoken = useSelector((state) => state.Auth.token);
    let filteredData = ticketsData?.map((j) => j?._id);


    useEffect(() => {
        let data = {
            ticketsIds: filteredData,
            userId: userData?._id
        }
        dispatch(updateTickets(data, usertoken, afterSuccess));
    }, []);

    const afterSuccess = () => {
        setTimeout(() => {
            let data = {
                Id: userData?._id
            }
            dispatch(getTickets(data, usertoken));
        }, 10000);

    };

    const rowClassName = (record) => {
        return record?.isRead === false ? "bold-row" : "";
    };
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const columns = [
        {
            title: "Visitor",
            dataIndex: "unique_id",
            width: 150,
            render: (text) => <>#[{text}]</>,
        },
        {
            title: "Name",
            dataIndex: "name",
            width: 200,
        },
        {
            title: "Email",
            dataIndex: "email",
            width: 200,
        },
        {
            title: "Message",
            dataIndex: "message",
        },
    ];




    const onSearch = (value) => { };

    return (
        <>
            <div className="topBtns">
                <div className="shortcut">
                    <Space direction="vertical">
                        <Search
                            placeholder="Search"
                            onSearch={onSearch}
                            style={{
                                width: 200,
                            }}
                        />
                    </Space>
                </div>
                <Button type="primary" onClick={() => navigate('/create-ticket')}>
                    Create Ticket
                </Button>
            </div>
            <div>
                <Table
                    columns={columns}
                    rowClassName={rowClassName}
                    dataSource={ticketsData?.map((item, index) => ({ ...item, key: index }))}
                    onRow={(i) => ({
                        // onClick: (e) => navigate("/shortcuts/addshortcut"),
                    })}
                />
            </div>
        </>
    );
}

export default Tickets;
