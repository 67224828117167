import { Post } from "../../helpers/apicalls/apicalls";
import * as actionTypes from "./WidgetTypes.js";

export const addWidget = (data, token, setSelectedImagebase64, message) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.IS_LOADING
    });
    Post("/agents/user/widget", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: actionTypes.WIDGET_ADDED_SUCCESS,
            payload: res.data
          });
          setSelectedImagebase64(null);
          message.success("Changes added successfully");
        }
        else {
          dispatch({
            type: actionTypes.WIDGET_ADDED_FAILED,
          });
          message.error("unsuccessful");
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.WIDGET_ADDED_FAILED,
        });
      });
  };
};

export const getWidgetdata = (data, token) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.IS_LOADING_GET
    });
    Post("/agents/user/get-widget", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: actionTypes.WIDGET_GET_SUCCESS,
            payload: res.data
          });
        }
        else {
          dispatch({
            type: actionTypes.WIDGET_GET_FAILED,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.WIDGET_GET_FAILED,
        });
      });
  };
};


