import React, { useEffect, useState, useContext } from "react";
import { UserOutlined, DownOutlined, LogoutOutlined } from "@ant-design/icons";
import { SocketContext } from "../../context/socketContext";
import { Collapse, Input, Space, Tag, Tooltip, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

export default function SideBarDrawer({ currentActiveVisitorData }) {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.Auth.userData);
    const { Socket } = useContext(SocketContext);
    const usertoken = useSelector((state) => state.Auth.token);

    const { TextArea } = Input;
    const clientInfo = useSelector((state) => state.Client.data);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [visitorNotes, setVisitorNotes] = useState("");
    const [chats, setChats] = useState(0);
    const [heightofModal, setheightofModal] = useState(0);
    const [textCopied, settextCopied] = useState(false);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    useEffect(() => {
        if (currentActiveVisitorData !== null) {
            setName(currentActiveVisitorData?.name);
            setEmail(currentActiveVisitorData?.email);
            setPhone(currentActiveVisitorData?.phone);
            setVisitorNotes(currentActiveVisitorData?.visitor_notes);
            setChats(currentActiveVisitorData?.chats);
        }
    }, [currentActiveVisitorData?.name,
    currentActiveVisitorData?.email,
    currentActiveVisitorData?.phone,
    currentActiveVisitorData?.visitor_notes,
    currentActiveVisitorData?.chats]);

    const updateClientInfo = () => {
        Socket.emit("updateVisitorInfo", {
            name: name, email: email, phone: phone, visitor_notes: visitorNotes,
            visitorId: currentActiveVisitorData?.unique_id,
            websiteToken: currentActiveVisitorData?.websiteToken
        });
    };

    // heloo
    const { Panel } = Collapse;
    const [open, setOpen] = useState(["1"]);
    const [openView, setOpenView] = useState(["1"]);
    const handleSubmit = (e) => {
        // e.preventDefault();
        if (open?.length > 0) {
            setOpen([]);
        } else {
            setOpen((prev) => [1]);
        }
    };

    const handleSubmitView = (e) => {
        // e.preventDefault();
        if (openView?.length > 0) {
            setOpenView([]);
        } else {
            setOpenView((prev) => [1]);
        }
    };
    // let counts = currentActiveVisitorData && currentActiveVisitorData?.url?.reduce((acc, obj) => {
    //     let url = obj?.url;
    //     if (acc[url]) {
    //         acc[url]++;
    //     } else {
    //         acc[url] = 1;
    //     }
    //     return acc;
    // }, {});
    // let result = counts && Object?.entries(counts).map(([url, count]) => ({
    //     url: url,
    //     count: count
    // }));

    // console.log(currentActiveVisitorData, "currentActiveVisitorData?.url")
    useEffect(() => {
        let observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                let currentHeight = parseFloat(entry.target.style.height);
                let newHeight = currentHeight - 170;
                let updatedHeight = newHeight + 'px';
                setheightofModal(updatedHeight)
            }
        });
        let elements = document.getElementsByClassName(currentActiveVisitorData?.unique_id);
        if (elements) {
            for (let i = 0; i < elements.length; i++) {
                observer.observe(elements[i]);
            }
        }
        return () => {
            observer.disconnect();
        };
    }, []);

    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url)
            .then(() => {
                settextCopied(true);
            })
            .catch(err => {
                settextCopied(false);
            });
    };

    return (
        <>
            <div style={{ height: heightofModal, paddingLeft: 10 }} className="currentHistory">
                <div className="visitorInfo">
                    <div className="visitorName">
                        <UserOutlined className="anticon-user2" />
                        <div>
                            <Input
                                style={{ margin: "2px 0px 2px 0px" }}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                name="name"
                                placeholder="Add name"
                                onBlur={() => updateClientInfo()}
                            />
                            <Input
                                style={{ margin: "2px 0px 2px 0px" }}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                name="email"
                                placeholder="Add email"
                                onBlur={() => updateClientInfo()}
                            />
                        </div>
                    </div>
                    <div className="visitorNum">
                        <Input
                            style={{ margin: "2px 0px 2px 0px" }}
                            type="number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            name="phone"
                            placeholder="Add phone number"
                            onBlur={() => updateClientInfo()}
                        />
                        <TextArea
                            rows={4}
                            value={visitorNotes}
                            name="visitor_notes"
                            onChange={(e) => setVisitorNotes(e.target.value)}
                            placeholder="Add visitor notes"
                            onBlur={() => updateClientInfo()}
                            maxLength={6000}
                            style={{
                                height: 120,
                                resize: "none",
                            }}
                        />
                        <div className="visitorCount">
                            <div>
                                <h4 style={{ fontWeight: '500', }}>{currentActiveVisitorData?.visits - 1}</h4>
                                <p style={{ fontSize: 13 }}>Past Visits</p>
                            </div>
                            <div>
                                <h4 style={{ fontWeight: '500', }}>{chats}</h4>
                                <p style={{ fontSize: 13 }}>Past Chats</p>
                            </div>
                            <div>
                                <h4 style={{ fontWeight: '500', }}>
                                    {moment(currentActiveVisitorData?.start_time).fromNow(true)}
                                </h4>
                                <p style={{ fontSize: 13 }}>Time on site</p>
                            </div>
                        </div>
                        <Collapse style={{ marginBottom: 5 }} activeKey={openView} onChange={handleSubmitView}>
                            <Panel
                                onChange={() => setOpenView((prev) => [1])}
                                header="Viewing"
                                key="1"
                            >
                                <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", alignItems: "flex-start", overflow: "hidden" }}>
                                    <p style={{ margin: 0 }}>({moment.utc(currentActiveVisitorData?.url?.[currentActiveVisitorData?.url?.length - 1]?.time).format("MM-DD-YY - HH:mm")})</p>
                                    <a style={{ marginBottom: 5, wordBreak: "break-all" }} href={currentActiveVisitorData?.url?.[currentActiveVisitorData?.url?.length - 1]?.url} target="_blank" >{currentActiveVisitorData?.url?.[currentActiveVisitorData?.url?.length - 1]?.url}</a>
                                </div>
                            </Panel>
                        </Collapse>

                        <Collapse activeKey={open} onChange={handleSubmit}>
                            <Panel
                                onChange={() => setOpen((prev) => [1])}
                                header="Visitor Path's"
                                key="1"
                            >
                                {currentActiveVisitorData?.url?.slice().reverse().map((urls, index) => {
                                    return <Tooltip placement="top" title={`${urls?.url} - (${moment.utc(urls?.time).format("MM,DD,YY - HH:mm")})`}>
                                        <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", alignItems: "flex-start", overflow: "hidden" }}>
                                            <p style={{ margin: 0 }}>({moment.utc(urls?.time).format("MM-DD-YY - HH:mm")})</p>
                                            <a style={{ marginBottom: 5, wordBreak: "break-all" }} href={urls?.url} target="_blank" key={index}>{urls?.url}</a>
                                        </div>
                                    </Tooltip>
                                })}
                                {/* {result?.map((i, index) => {

                                    let newString = i?.url?.length > 40 ? i?.url?.substring(0, 80 / 2) + '...' : i?.url;

                                    return (
                                        <Tooltip placement="top" title={`${i?.url} - (${i?.count})`}>
                                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", overflow: "hidden" }}>
                                                <a href={i?.url} target="_blank" key={index}>{newString}</a>&nbsp; &nbsp; <p>({i?.count})</p>
                                            </div>
                                        </Tooltip>
                                    );
                                })} */}
                                {/* <p>{clientInfo?.brand?.brandname}</p> */}
                            </Panel>
                        </Collapse>

                        <div className="visitorGeoInfo">
                            <div className="listFlex">
                                <p>Referrer Url: </p>
                                <Tooltip placement="top" title={textCopied ? "Copied" : "Copy"}>
                                    <span style={{ textDecoration: "underline", cursor: "pointer", wordBreak: "break-all" }} onClick={() => copyToClipboard(currentActiveVisitorData?.referrerUrl)} id="url"> {currentActiveVisitorData?.referrerUrl}</span>
                                </Tooltip>
                            </div>
                            <div className="listFlex">
                                <p>Location :</p>
                                <span>{currentActiveVisitorData?.location?.city && `${currentActiveVisitorData?.location?.city},`}{currentActiveVisitorData?.location?.region && `${currentActiveVisitorData?.location?.region},`}{currentActiveVisitorData?.location?.country}</span>
                            </div>
                            <div className="listFlex">
                                <p>Browser :</p>
                                <span>{currentActiveVisitorData?.data?.browser}</span>
                            </div>
                            <div className="listFlex">
                                <p>Platform :</p>
                                <span>{currentActiveVisitorData?.data?.platform}</span>
                            </div>
                            <div className="listFlex">
                                <p>Device :</p>
                                <span>
                                    {currentActiveVisitorData?.data
                                        ? currentActiveVisitorData?.data?.isMobileDevice
                                            ? "Mobile"
                                            : currentActiveVisitorData?.data?.isTabletdDevice
                                                ? "Tablet"
                                                : "PC"
                                        : "-"}
                                </span>
                            </div>
                            <div className="listFlex">
                                <p>IP Address :</p>
                                <span>{currentActiveVisitorData?.ip}</span>
                            </div>
                            <p>User agent :</p>
                            <span>{currentActiveVisitorData?.data?.userAgent}</span>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
