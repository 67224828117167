import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import bird from "../assets/image/bird.png"

export const fileChanger = (file, filetype) => {
    const binaryString = file !== null && atob(file);
    const binaryData = new Uint8Array(binaryString?.length);
    for (let i = 0; i < binaryString?.length; i++) {
        binaryData[i] = binaryString?.charCodeAt(i);
    }
    const blob = new Blob([binaryData], { type: filetype });
    return blob;
}

export function arrayBufferToBase64(arrayBuffer) {
    const uint8Array = new Uint8Array(arrayBuffer);
    const binaryString = uint8Array.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
    return btoa(binaryString);
}

export const showNotification = (senderId, message) => {
    var options = {
        body: message,
        icon: bird,
        dir: 'ltr',
    };
    new Notification(senderId, options);
}