import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getPastMessages } from "../../redux/Chats/PastChatsActions";
import DragPastChatHistory from "./dragPastChatHistory";

const DragPastChat = ({ currentActiveVisitorData }) => {
    const usertoken = useSelector((state) => state.Auth.token);
    const pastChats = useSelector((state) => state.PastChat.data);
    const pastChatsLoading = useSelector((state) => state.PastChat.isLoading);
    const [messages, setMessages] = useState([]);
    const [expandedKey, setExpandedKey] = useState(null);
    const dispatch = useDispatch();
    const onExpand = (_, key) => {
        setExpandedKey((prev) => {
            const newKey = key.key;
            if (prev !== newKey) {
                return newKey;
            }
            return null;
        });
    };

    useEffect(() => {
        setMessages(pastChats);
    }, [pastChats]);

    useEffect(() => {
        var data = {
            unique_id: currentActiveVisitorData?.unique_id
        };
        dispatch(getPastMessages(data, usertoken));
    }, []);

    const columns = [
        {
            title: "Agent",
            dataIndex: "agent",
        },
        // {
        //     title: "Satisfaction Time",
        //     dataIndex: "satisfaction",
        // },
        {
            title: "Message",
            dataIndex: "message"
        },
        {
            title: "",
            dataIndex: "index",
            render: (_, index) => (
                <p style={{ textAlign: "end" }}>{index?.key == expandedKey ? "-" : "+"}</p>
            ),
        }
    ];

    // const data = [];
    const data = messages?.map((message, index) => {
        return {
            key: index,
            id: message._id,
            unique_id: currentActiveVisitorData?.unique_id,
            agent: message?.convo_agents.length > 1
                ? message?.convo_agents?.map((agent, index) => {
                    return agent?.sudoName;
                }) + ", "
                : message?.convo_agents?.length > 0 ? message?.convo_agents?.map((agent, index) => {
                    return agent?.sudoName;
                }) : 'No Agent',
            satisfaction: `Yes`,
            message: message?.convo_messages[0].message,
            convoMessages: message?.convo_messages,
            convoAgents: message?.convo_agents,
            createdAt: message?.createdAt
        }
    });

    return (
        <div>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                expandRowByClick={true}
                expandIconColumnIndex={-1}
                loading={pastChatsLoading}
                expandable={{
                    expandedRowRender: (record) => (
                        <DragPastChatHistory record={record} setExpandedKey={setExpandedKey} />
                    ),
                    onExpand: onExpand,
                    expandedRowKeys: [expandedKey]
                }}
                scroll={{
                    y: 489
                }}
            />
        </div>
    );
};

export default DragPastChat;
