import * as actionTypes from "./BrandsTypes";
import * as userActionTypes from "../Auth/AuthTypes";
import { Post, Get } from "../../helpers/apicalls/apicalls";

export const Get_Brands_By_Id = (data, token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING });
    Post("/agents/user/brand/get-brands-by-company-id", data, token)
      .then(function (response) {
        if (response) {
          dispatch({
            type: actionTypes.BRAND_DATA_SUCCESS,
            payload: response?.data
          });
        } else {
          dispatch({ type: actionTypes.BRAND_DATA_FALED });
          alert(response.message);
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.BRAND_DATA_FALED });
      });
  };
};

export const Get_All_Brands = (token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING });
    Get("/agents/user/brand", token)
      .then(function (response) {
        if (response) {
          dispatch({
            type: actionTypes.BRAND_DATA_SUCCESS,
            payload: response?.data
          });
        } else {
          dispatch({ type: actionTypes.BRAND_DATA_FALED });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.BRAND_DATA_FALED });
      });
  };
};

export const Add_Brand = (data, token, afterSuccess, afterAdded) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_ADD_LOADING });
    Post("/agents/user/brand/register", data, token)
      .then(function (response) {
        if (response.status) {
          dispatch({
            type: userActionTypes.USER_DATA_SUCCESS,
            payload: response?.data
          });
          afterSuccess();
          afterAdded();
        } else {
          dispatch({ type: actionTypes.BRAND_ADD_DATA_FALED });
        }
      })
      .catch(function (error) {

        dispatch({ type: actionTypes.BRAND_ADD_DATA_FALED });
        afterAdded();
      });
  };
};

export const Brand_Status = (data, token, afterAdded) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_BRAND_ACTIVE_LOADING });
    Post("/agents/user/brand/status", data, token)
      .then(function (response) {
        if (response.status) {
          dispatch({
            type: actionTypes.BRAND_ACTIVE_DATA_SUCCESS,
            payload: response?.data
          });
          afterAdded();
        } else {
          dispatch({ type: actionTypes.BRAND_ACTIVE_DATA_FALED });
        }
      })
      .catch(function (error) {

        dispatch({ type: actionTypes.BRAND_ACTIVE_DATA_FALED });
      });
  };
};

export const Brand_By_Adminid = (data, token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_BRAND_BY_ADMINID_LOADING });
    Post("/agents/user/brand/get-brands-by-company-id", data, token)
      .then(function (response) {
        if (response.status) {
          dispatch({
            type: actionTypes.BRAND_BY_ADMINID_DATA_SUCCESS,
            payload: response?.data
          });

        } else {
          dispatch({ type: actionTypes.BRAND_BY_ADMINID_DATA_FALED });
        }
      })
      .catch(function (error) {

        dispatch({ type: actionTypes.BRAND_BY_ADMINID_DATA_FALED });
      });
  };
};

