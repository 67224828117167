export const IS_LOADING = "IS_LOADING";
export const BRAND_DATA_SUCCESS = "BRAND_DATA_SUCCESS";
export const BRAND_DATA_FALED = "BRAND_DATA_FALED";

export const IS_ADD_LOADING = "IS_ADD_LOADING";
export const BRAND_ADD_DATA_SUCCESS = "BRAND_ADD_DATA_SUCCESS";
export const BRAND_ADD_DATA_FALED = "BRAND_ADD_DATA_FALED";

export const IS_BRAND_ACTIVE_LOADING = "IS_BRAND_ACTIVE_LOADING";
export const BRAND_ACTIVE_DATA_SUCCESS = "BRAND_ACTIVE_DATA_SUCCESS";
export const BRAND_ACTIVE_DATA_FALED = "BRAND_ACTIVE_DATA_FALED";

export const IS_BRAND_BY_ADMINID_LOADING = "IS_BRAND_BY_ADMINID_LOADING";
export const BRAND_BY_ADMINID_DATA_SUCCESS = "BRAND_BY_ADMINID_DATA_SUCCESS";
export const BRAND_BY_ADMINID_DATA_FALED = "BRAND_BY_ADMINID_DATA_FALED";
