import React, { useState, useEffect } from "react";
import { Table, Badge, Tabs, Button, DatePicker, Input, Popconfirm, } from "antd";
import { useDispatch, useSelector } from "react-redux";

import TransTab from "./TransTab";
import UserTab from "./UserTab";
import { CloseOutlined, AudioOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { getVisitorAllHistory } from "../../redux/AllHistory/AllHistoryActions";
import moment from "moment";
// import { GetOverAllUsers } from "../../redux/Users/UsersActions";
import { updateTickets } from "../../redux/Tickets/TicketsActions";
import { useNavigate } from "react-router-dom";


const Currenthistory = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.Auth.userData);
  const usertoken = useSelector((state) => state.Auth.token);
  const overAllUsers = useSelector((state) => state.Users.overAllUsers);
  const visitorData = useSelector((state) => state.ALLHistoryReducer.dataH);
  const isLoadingH = useSelector((state) => state.ALLHistoryReducer.isLoadingH);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isShow, setisShow] = useState(false);
  const [colorCode, setColorCode] = useState(null);
  const [dateFilter, setdateFilter] = useState(null);

  const [dateString, setdateString] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);


  const handleTableChange = (pagination, page) => {
    setCurrentPage(pagination);
    setPageSize(page);
  };

  const [allData, setallData] = useState([]);
  const { TabPane } = Tabs;
  const [selectedData, setselectedData] = useState(null);
  const [filteredDate, setFilteredDate] = useState(null); // State to store the selected date filter

  const { Search } = Input;

  const handleDateChange = (date, dateString) => {
    setFilteredDate(dateString);
  };

  useEffect(() => {
    if (visitorData?.length > 0) {
      setallData(visitorData);
    } else {
      setallData([])
    }
  }, [visitorData]);

  const navigate = useNavigate();

  // useEffect(() => {
  //   dispatch(GetOverAllUsers(usertoken))
  // }, []);

  const onChange = (date, dateString) => {
    if (date !== "") {
      setdateFilter(date);
      setdateString(dateString);
    }
    else {
      setdateFilter(null);
      setdateString(null)
    }
  };

  useEffect(() => {
    let allTokens = userData?.assignBrands?.map((i) => i?.token);
    if (dateFilter != null) {
      let Data = {
        brand_token: allTokens,
        date: dateFilter,
        currentPage: currentPage,
        pageSize: pageSize

      };
      dispatch(getVisitorAllHistory(Data, usertoken));
    } else {
      let Data = {
        brand_token: allTokens,
        date: moment(new Date()),
        currentPage: currentPage,
        pageSize: pageSize
      };
      dispatch(getVisitorAllHistory(Data, usertoken));
    }
  }, [dateFilter, pageSize, currentPage]);



  const columns = [
    {
      title: "Name",
      // dataIndex: "unique_id",
      width: 150,
      render: (text, item) => {
        return <div onClick={() => handleRowClick(item)} className="visitorIcon">{text?.name ? text?.name : `#[${text?.unique_id}]`}</div>
      }
    },
    {
      title: "Agent",
      // dataIndex: "visitor",
      width: 150,
      render: (text) => {
        if (text?.pastServedBy?.length > 0) {
          let filtredUser = overAllUsers?.find((i) => i?._id == text?.pastServedBy?.[0]?.id);
          return <div className="visitorIcon">{filtredUser?.sudoName}</div>
        } else {
          let filtredUser = overAllUsers?.find((i) => i?._id == text?.servedBy?.[text?.servedBy?.length - 1]?.id);
          return <div className="visitorIcon">{filtredUser?.sudoName}</div>
        }

      }
    },
    {
      title: 'Time',
      // dataIndex: 'visitor',
      width: 240,
      render: (text) => (
        <div className="visitorIcon">
          {moment(text?.updatedAt).format("DD-MM-YYYY")}
        </div>
      ),
      // filteredValue: filteredDate ? [moment.utc(filteredDate, 'DD-MM-YYYY')] : null,
      // filterDropdown: () => (
      //   <div style={{ padding: 8 }}>
      //     <DatePicker format="DD-MM-YYYY"
      //       onChange={handleDateChange} />
      //   </div>
      // ),
      // onFilter: (value, record) => {
      //   const selectedDate = moment.utc(value).format("DD-MM-YYYY"); // Format selected date
      //   const recordDate = moment.utc(record?.visitor?.updatedAt).format("DD-MM-YYYY"); // Format date in record
      //   return recordDate === selectedDate;
      // },
    },
    {
      title: "Messages",
      render: (text) => {
        const fetchMessageData = () => {
          return text?.messages
            .sort((x, y) => {
              return new Date(x?.time).toUTCString() < new Date(y?.time).toUTCString()
                ? 1
                : -1;
            })
            .reverse();
        };
        const textTypeArray = fetchMessageData()?.filter(item => item?.type === 'text' || item?.to);

        return <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="msgBadges">
            {fetchMessageData()?.length > 0 && (
              fetchMessageData()?.length
            )}
          </div>
          <div>
            {textTypeArray?.[textTypeArray?.length - 1]?.message !== "" ? (
              textTypeArray?.[textTypeArray?.length - 1]?.message
            ) : (
              textTypeArray?.[textTypeArray?.length - 1]?.file?.filename
            )}
          </div>

        </div>
      }
    },
    // {
    //   title: "Action",
    //   render: (text) => <div style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer" }}>
    //     <div style={{ padding: "0px 5px 0px 5px" }}>
    //       <Popconfirm
    //         placement="topLeft"
    //         title="Delete the shortcut"
    //         description="Are you sure to delete this shortcut?"
    //         // onConfirm={() => DeleteShortcut(text?._id)}
    //         okText="Yes"
    //         cancelText="No"
    //       >
    //         {/* <Button danger>Delete</Button> */}
    //         <DeleteOutlined style={{ fontSize: 17 }} />
    //       </Popconfirm>
    //     </div>
    //   </div>
    // }
  ];

  useEffect(() => {
    if (isShow) {
      setColorCode(Math.random().toString(16).substr(-6));
    }
  }, [isShow]);


  const handleRowClick = (record) => {
    setselectedData(record);
    upDateIsRead(record?.visitor?.unique_id, record?.visitor?.isRead);
    setisShow(true);
  };

  const handleCloseSidebar = () => {
    setisShow(false);
  };

  const upDateIsRead = (id, isRead) => {
    if (!isRead) {
      let data = {
        ticketsIds: id,
        userId: userData?._id
      }
      dispatch(updateTickets(data, usertoken, afterSuccess));
    }
  };
  const afterSuccess = () => {
    let allTokens = userData?.assignBrands?.map((i) => i?.token);
    let Data = {
      brand_token: allTokens,
    };
    dispatch(getVisitorAllHistory(Data, usertoken));
  };


  const rowClassName = (record) => {
    return record?.visitor?.isRead === false ? "bold-row" : "";
  };


  const onSeachData = (data) => {

    const findingTxt = visitorData && visitorData?.data && visitorData?.data?.filter((i) =>
      data !== ""
        ? i?.unique_id.toLowerCase().includes(data.toLowerCase()) ||
        i?.name.toLowerCase().includes(data.toLowerCase()) ||
        i?.name.toLowerCase().includes(data.toLowerCase()) ||
        (i?.messages && i.messages.some(x => x?.message.toLowerCase() === data.toLowerCase()))
        : i
    );
    setallData(findingTxt);
  }

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };
  return (
    <div className="table-container-sidebarnew">
      <div className="historytopbtn">
        <div>
          <Search
            placeholder="Search"
            onChange={(x) => onSeachData(x.target.value)}
            onSearch={(value) => onSeachData(value)}
            style={{
              width: 200,
              marginRight: 10
            }}
          />
          <DatePicker
            disabledDate={disabledDate}
            format="DD-MM-YYYY"
            onChange={onChange}
          />
        </div>
        {/* <Button onClick={() => navigate("/all-history")} type="primary" >
          All Visitors
        </Button> */}
      </div>
      <Badge.Ribbon text={dateFilter !== null ? dateString : "Today's Record's"} color="rgb(86, 119, 122)">
        <Table
          loading={isLoadingH}
          dataSource={allData?.map((item, index) => ({ ...item, key: index }))}
          columns={columns}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: visitorData?.totalCount,
            showTotal: (total, range) => `Total ${total} records | Showing ${range[0]}-${range[1]} records`,
            onChange: handleTableChange,
          }}
          rowClassName={rowClassName}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => handleRowClick(record),
            };
          }}
          style={{ marginTop: "20px" }}
        />
      </Badge.Ribbon>
      <div>
        <div className={`sidebarnew ${isShow ? "open" : "closed"}`}>
          <a className="crossBtn" onClick={handleCloseSidebar}>
            <CloseOutlined />
          </a>
          <div className="currentHistory">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Transcript" key="1">
                <TransTab selectedData={selectedData} colorCode={colorCode} />
              </TabPane>
              <TabPane tab="User" key="2">
                <UserTab selectedData={selectedData} />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Currenthistory;