import * as actionTypes from "./WidgetTypes.js";

const INTIAL_STATE = {
  data: [],
  isLoadingwidget: false,
  widgetData: null,
  widgetLoading: false
};

const WidgetReducer = (state = INTIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.IS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.WIDGET_ADDED_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false
      };
    case actionTypes.WIDGET_ADDED_FAILED:
      return {
        ...state,
        isLoading: false
      };
    /////////////////////////////////////////
    case actionTypes.IS_LOADING_GET:
      return {
        ...state,
        widgetLoading: true
      };
    case actionTypes.WIDGET_GET_SUCCESS:
      return {
        ...state,
        widgetData: payload,
        widgetLoading: false
      };
    case actionTypes.WIDGET_GET_FAILED:
      return {
        ...state,
        widgetLoading: false
      };
    case actionTypes.WIDGET_GET_CLEAN:
      return {
        ...state,
        widgetData: null,
        widgetLoading: false
      };
    default:
      return state;
  }
};

export default WidgetReducer;
