// BarChart.js

import React from 'react';
import { Bar } from 'react-chartjs-2';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
Chart.register(CategoryScale);

const BarChart = ({ AnalyticsData }) => {
  // Sample data
  let allKeys = AnalyticsData?.TotalVisits.length > 0 ? Object?.keys(AnalyticsData?.TotalVisits[0]) : ["There is no Data Currently"];
  let allValues = AnalyticsData?.TotalVisits.length > 0 ? Object?.values(AnalyticsData?.TotalVisits[0]) : [0];
  const data = {
    labels: allKeys,
    datasets: [
      {
        label: 'Visits',
        data: allValues,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 2,
        hoverBackgroundColor: 'rgba(75,192,192,0.4)',
        hoverBorderColor: 'rgba(75,192,192,1)',
      },
    ],
  };

  // Chart options
  const options = {
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return <div style={{ display: "flex", justifyContent: "center", alignItems: "end", height: 410 }}> <Bar data={data} options={options} /></div>;
};

export default BarChart;
