export const IS_LOADING = "IS_LOADING";
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
export const USER_TOKEN = "USER_TOKEN";
export const USER_DATA_FALED = "USER_DATA_FALED";
export const USER_LOGOUT = "USER_LOGOUT";

export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FALED = "SIGNUP_USER_FALED";


export const IS_LOADING_PASSWORD = "IS_LOADING_PASSWORD";
export const _PASSWORD_SUCCESS = "_PASSWORD_SUCCESS";
export const _PASSWORD_FALED = "_PASSWORD_FALED";


export const PACKEGS_LOADING_PASSWORD = "PACKEGS_LOADING_PASSWORD";
export const PACKEGS_SUCCESS = "PACKEGS_SUCCESS";
export const PACKEGS_FALED = "PACKEGS_FALED";


export const PLAN_TYPE_LOADING_PASSWORD = "PLAN_TYPE_LOADING_PASSWORD";
export const PLAN_TYPE_SUCCESS = "PLAN_TYPE_SUCCESS";
export const PLAN_TYPE_FALED = "PLAN_TYPE_FALED";

