import { Col, Row } from "antd";
import ClipboardCopy from "../../components/clipboard";

const Tab3 = () => {
  return (
    <Row className="getstarted">
      <Col span={12}>
        <h6>Embed Web Widget (Classic)</h6>
        <p>
          Copy the following script and insert it into your website's HTML
          source code between the head tags.
          <br />
          This code must be inserted into every page where you want to display
          the widget.
        </p>

        <h1>Copy to Clipboard Example</h1>
        <ClipboardCopy />

        <h6>Embed Web Widget (Classic)</h6>
        <p>
          You can configure your widget to ensure that visitors you are talking
          to are your actual customers. <br />
          Set up visitor authentication or Learn more
        </p>
      </Col>
    </Row>
  );
};
export default Tab3;  