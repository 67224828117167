import * as actionTypes from "./UsersTypes";

const INTIAL_STATE = {
  data: null,
  isLoading: false,
  BrandAgents: [],
  allUsers: [],
  overAllUsers: []
};

const UsersReducer = (state = INTIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.IS_LOADING:
      return {
        ...state,
        isLoading: true
      };

    case actionTypes.Users_DATA_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false
      };
    case actionTypes.Users_DATA_FALED:
      return {
        ...state,
        isLoading: false
      };

    // Agents by brand Reducer
    case actionTypes.AGENT_BY_BRAND_SUCCESS:
      return {
        ...state,
        BrandAgents: payload
      };

    case actionTypes.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        allUsers: payload
      };

    case actionTypes.GET_USER_DATA_FAILED:
      return {
        ...state,
        isLoading: false
      };

    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allUsers: payload
      };

    case actionTypes.UPDATE_USER_FAILED:
      return {
        ...state,
        isLoading: false
      };

    ///////////////////

    case actionTypes.GET_USER_ALL_SUCCESS:
      return {
        ...state,
        overAllUsers: payload
      };
    case actionTypes.GET_USER_ALL_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default UsersReducer;
