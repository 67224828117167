import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);



export function PieChart({ AnalyticsData }) {
  // let allKeys = AnalyticsData?.TicketsCount?.result?.length > 0 ? AnalyticsData?.TicketsCount?.result?.map((i) => i?.isRead) : ["There is no Data Currently"];
  let allValues = AnalyticsData?.TicketsCount?.result?.length > 0 ?  AnalyticsData?.TicketsCount?.result?.map((i) => i?.count) :[0,0];
  const data = {
    labels: ['Pending', 'Done'],
    datasets: [
      {
        label: '# of Tickets',
        data: [...allValues],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  return <div style={{ display: "flex", justifyContent: "center", alignItems: "end", height: 410 }}><Pie data={data} /> </div>;
}